.img-thumb {
  width: 100%;
  height: auto;
  max-width: 300px;
}

.thumb {
  $width: 100%;
  @include thumb($width);
}

.thumb-title {
  margin: 5px;
}

.img-thumb.list {
  width: auto;
  height: 80px;
  max-width: 300px;
}

.thumb.list {
  $height: 80px;
  @include thumblist($height);
}

