// Here you can add other styles
.file-upload {
  position: relative;
  margin: 25px 0 15px;
  border: 2px dotted lightgray;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;


  input {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: color 500ms ease-in-out;

    &:focus {
      outline: none;
    }


  }

  p {
    font-weight: bold;
    letter-spacing: 2.2px;
    margin-top: 0;
    text-align: center;
  }

  button {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #007bff;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 1.1em 2.8em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 6px;
    color: #007bff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 250ms ease-in-out;
    font-family: "Open Sans", sans-serif;
    width: 45%;
    display: flex;
    align-items: center;
    padding-right: 0;
    justify-content: center;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: #007bff;
      z-index: -1;
      transition: width 250ms ease-in-out;
    }

    &.uploading {
      color: #fff;
      outline: 0;
      background: transparent;

      &:after {
        width: 110%;
      }
    }

    i {
      font-size: 22px;
      margin-right: 5px;
      border-right: 2px solid;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and (max-width: 500px) {
      width: 70%;
    }

    @media only screen and (max-width: 350px) {
      width: 100%;
    }

    &:hover {
      color: #fff;
      outline: 0;
      background: transparent;

      &:after {
        width: 110%;
      }
    }


    &:focus {
      outline: 0;
      background: transparent;
    }

    &:disabled {
      opacity: 0.4;
      filter: grayscale(100%);
      pointer-events: none;
    }
  }
}

.fixed-left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1030;
}

.compile-buttons {
  border: solid thin $gray-300;
  box-shadow: 0 0 5px $black;
}

.tablet-mode {
  margin-top: 55px;
}

.compile-table {
  margin-top: 5vh;
  margin-left: 7vw;
  margin-bottom: 5vh;
  width: calc(100% - 5vw);
}

.compile-table-desktop {
  margin-bottom: 0;
}

.full-width {
  width: 100vw !important;
  padding: 5vh 10vw;
}

.full-height {
  height: calc(100vh - 55px) !important;
  padding: 0 2vw;
  margin-top: 60px;
}

.compile-table input.form-control {
  max-width: 100px;
}


.compile-table .compile-border-top {
  border-top: calc(35vh + 2em) #fff solid;
}

.compile-table .compile-border-bottom {
  border-bottom: 10rem #fff solid;
}

#new-name::placeholder, #new-amount::placeholder {
  color: $gray-500;
}

@media only print {
  .noprint {
    display: none;
  }
}

.loading-canvas {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000000;
  display: none;
}

#withdraw-form {
  padding: 2em;
  border: solid thin #000;
}

@media only print {
  #withdraw-form {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
}

.cookie-consent {
  top: 20% !important;
  width: 80% !important;
  left: 10% !important;
  padding: 1em;
}

.cookie-consent-overlay {
  z-index: 10000 !important;
}

.language-selector {
  max-height: calc(50px - 1rem);
}

.language-selector button {
  padding: 5px !important;
}

.language-selector img {
  height: calc(40px - 1rem);
  width: auto;
}

@page {
  size: auto;
  margin: 0mm;
}

.leaf-loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.2);
}


@keyframes sway {
  0% {
    transform: rotateZ(-15deg) rotateX(15deg);
  }
  30% {
    transform: rotateZ(20deg) rotateX(30deg);
    animation-timing-function: ease-in-out;
  }
  60% {
    transform: rotateZ(-20deg) rotateX(15deg);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: rotateZ(0deg) rotateX(0deg);
    animation-timing-function: cubic-bezier(0.990, 0.000, 0.890, 0.435);
  }
}

@keyframes fall {
  60% {
    filter: drop-shadow(0px 60px 40px rgba(0, 0, 0, 0));
  }
  100% {
    filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.6));
  }
}

.leaf-loading .leaf {
  position: absolute;
  transform-origin: 0px 0px 0px;
  animation-name: fall, sway;
  animation-duration: 4s, 4.3s;
  animation-fill-mode: both;
  animation-timing-function: linear, ease-in-out;
}

.svg-logo {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.intro_tut_announce {
  position: fixed;
  right: 5px;
  bottom: 55px;

}

@keyframes popup {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3) translate(-15px, -15px);
  }
  100% {
    transform: scale(1);
  }
}

.intro_tut_announce button {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: white;
  animation: popup 1s ease;
}

.handle {
  cursor: move;
}

.draggable {
  background-color: $success;
}


.CalendarDay__selected_span {
  background: $slowVitaGreen !important; //background
  color: white; //text
  border: 1px solid $slowVitaGreen !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $gray-600 !important;
  color: white;
  border: 1px solid $gray-600 !important; //default styles include a border
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $gray-600 !important;
  color: white !important;
  border: 1px solid $gray-600 !important; //default styles include a border
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $slowVitaGreen !important;
  color: white !important;
  border: 1px solid $slowVitaGreen !important; //default styles include a border
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid $slowVitaGreen !important;
}


.background-orders-0 {
  background-color: rgba(255, 0, 0, 0.2);
}

.background-orders-1 {
  background-color: rgba(0, 255, 0, 0.2);
}

.background-orders-2 {
  background-color: rgba(0, 0, 255, 0.2);
}

.background-orders-3 {
  background-color: rgba(255, 255, 0, 0.2);
}

.background-orders-4 {
  background-color: rgba(0, 255, 255, 0.2);
}

.background-orders-5 {
  background-color: rgba(255, 0, 255, 0.2);
}

.background-orders-6 {
  background-color: rgba(128, 255, 0, 0.2);
}

.background-orders-7 {
  background-color: rgba(0, 255, 128, 0.2);
}

.background-orders-8 {
  background-color: rgba(255, 128, 0, 0.2);
}

.background-orders-9 {
  background-color: rgba(255, 0, 128, 0.2);
}

.background-orders-10 {
  background-color: rgba(128, 0, 255, 0.2);
}

.background-orders-11 {
  background-color: rgba(0, 128, 255, 0.2);
}

.background-orders-12 {
  background-color: rgba(255, 128, 255, 0.2);
}

.background-orders-13 {
  background-color: rgba(255, 255, 128, 0.2);
}

.background-orders-14 {
  background-color: rgba(128, 255, 255, 0.2);
}

.background-orders-15 {
  background-color: rgba(128, 128, 255, 0.2);
}

.background-orders-15 {
  background-color: rgba(128, 255, 128, 0.2);
}

.background-orders-15 {
  background-color: rgba(255, 128, 128, 0.2);
}

.background-orders-opaque {
  opacity: 0.6;
}

.logged-out .app {
  position: absolute;
  top: 55px;
  left: 0;
  padding-bottom: 50px;
  min-width: 100vw;
}

.instruction_image {
  width: 100%;
}

.q-a {
  max-height: 4.5rem;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  &.open {
    max-height: 100rem;
  }
}

.question {
  cursor: pointer;
  font-weight: bold;
  line-height: 1rem;
  font-family: 'Lato', sans-serif;

  .circled-enum {
    border: solid thin $slowVitaGreen;
    border-radius: 100%;
    background-color: $slowVitaYellow;
    float: left;
    line-height: 3rem;
    align-content: center;
    align-items: center;
    vertical-align: center;
    width: 3rem;
    height: 3rem;
  }

}

.answer {
  padding-top: 3rem;
  margin-left: 4.25rem;
  text-align: left;
}

.new-msg-button {
  position: absolute;
  left: 0;
  bottom: 0.2375rem;
  right: 0;
}

input[type=checkbox].lg-checkbox {
  width: 30px;
  height: 30px;
}

.has-tooltip {
  .tooltip-track {
    width: calc(100% - 16px);
    margin-left: 8px;
    position: relative;
    height: 15px;

    .my-tooltip {
      position: absolute;
      display: block;
      top: 5px;
      width: 7ch;
      background: white;
      color: black;
      text-align: center;
      transform: translateX(-3.5ch);
      box-shadow: 0 0 2px 0 $slowVitaGreen;

      &::before {
        content: '';
        top: -5px;
        position: absolute;
        border-style: solid;
        border-color: transparent transparent $slowVitaGreen transparent;
        border-width: 0 5px 5px 5px;
        left: calc(3.5ch - 5px);
      }
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {

    input[type='range'] {
      -webkit-appearance: none;
      background-color: $slowVitaYellow;
      margin-bottom: 10px;
    }
    input[type='range']::-webkit-slider-runnable-track {
      height: 5px;
      -webkit-appearance: none;
      margin-top: -1px;
      color: $slowVitaGreen;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 16px;
      -webkit-appearance: none;
      height: 16px;
      margin-top: -5px;
      cursor: ew-resize;
      background: $slowVitaGreen;
      border-radius: 100%;
    }
  }
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: $slowVitaGreen;
  }

  input[type="range"]::-moz-range-track {
    background-color: $slowVitaYellow;
  }

  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: $slowVitaGreen;
  }

  input[type="range"]::-ms-fill-upper {
    background-color: $slowVitaYellow;
  }
}

.app-badge {
  display: block;
  width: 100%;

  .ios {
    margin: 1em
  }
}

.pdf-preview {
  width: 100%;
  aspect-ratio: 1 / 1.4142; // A4 Aspect ratio
}

.clickable {
  cursor: pointer;
}

.app.small-app{
  min-height: 80vh;
}

::-webkit-scrollbar {
  background-color: $slowVitaYellow;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: $slowVitaGreen;
  border-radius: 0.5rem;
  width: 6px;
}
