.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $spacer;
  color: $footer-color;
  background: $footer-bg;
  @include borders($footer-borders);


  .navbar-nav {
    flex-direction: row;
    align-items: center;
    .nav-link {
      padding: 0 0.5rem;
      display: inline-block;
      cursor: pointer;
    }
  }
}
